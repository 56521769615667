import React from 'react';
import NavbarComponent from '../components/Navbar'; // Cambia la importación aquí
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import Portfolio from '../components/Portfolio';
import About from '../components/About';
import Quotation from '../components/Quotation';

const Home = () => (
  <div>
    <NavbarComponent />
    <HeroSection />
    <Services />
    <Portfolio />
    <About />
    <Quotation />
  </div>
);

export default Home;
