import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  padding: 4rem 2rem;
  background: ${({ theme }) => theme.colors.background}; /* Fondo del tema */
`;

const AboutContent = styled.div`
  max-width: 800px;
  margin: auto;
  text-align: center;
`;

const AboutTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary}; /* Color del título */
  margin-bottom: 1rem;
`;
const AboutText = styled.p`
  color: #000; /* Color negro para el texto */
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const About = () => (
  <AboutSection>
    <AboutContent>
      <AboutTitle>Sobre Nosotros</AboutTitle>
      <AboutText>
        En Onva Software, nos especializamos en el desarrollo de sitios web de alta calidad, diseñados para satisfacer las necesidades únicas de cada negocio. Contamos con un equipo de desarrolladores y diseñadores experimentados, comprometidos con ofrecer soluciones innovadoras que mejoren su presencia en línea y generen resultados tangibles.
      </AboutText>
      <AboutText>
        Nuestra misión es ayudar a empresas como la suya a prosperar en el mundo digital. Ya sea que necesite un sitio web moderno y elegante para exhibir sus servicios o una plataforma de comercio electrónico completa, tenemos la experiencia para hacer realidad su visión. Nuestro enfoque combina tecnología de vanguardia con una profunda comprensión de la experiencia del usuario para crear sitios web que no solo sean visualmente atractivos, sino también altamente funcionales.
      </AboutText>
      <AboutText>
        Con un enfoque en la satisfacción del cliente y el éxito a largo plazo, trabajamos estrechamente con usted para comprender sus objetivos y ofrecer un producto que supere sus expectativas. Descubra cómo podemos transformar su presencia en línea y diferenciar su negocio de la competencia.
      </AboutText>
    </AboutContent>
  </AboutSection>
);

export default About;
