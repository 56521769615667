import React from 'react';
import Header from '../components/Navbar';
import Services from '../components/Services';

const ServicesPage = () => (
  <>
    <Header />
    <Services />
  </>
);

export default ServicesPage;
