import React, { useState } from 'react';
import styled from 'styled-components';

const ContactSection = styled.section`
  padding: 4rem 2rem;
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que la sección ocupe toda la altura de la ventana */
`;

const ContactForm = styled.form`
  max-width: 600px;
  width: 100%;
  background: #fff; /* Fondo blanco para el formulario */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* Espacio entre los elementos del formulario */
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: #000; /* Color negro para el texto de la etiqueta */
  font-size: 1rem;
  font-weight: 600;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #000; /* Color negro para el texto del campo de entrada */
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #000; /* Color negro para el texto del área de texto */
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: none;
  }
`;

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [comments, setComments] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappMessage = `
*Nombre:* ${name}
*Correo Electrónico:* ${email}
*Tipo de Servicio:* ${service}
*Comentarios Adicionales:* ${comments}
    `;

    const encodedMessage = encodeURIComponent(whatsappMessage.trim());
    const whatsappUrl = `https://wa.me/8132396280?text=${encodedMessage}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <ContactSection>
      <ContactForm onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">Nombre</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Correo Electrónico</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="service">Tipo de Servicio</Label>
          <Input
            type="text"
            id="service"
            name="service"
            value={service}
            onChange={(e) => setService(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="comments">Comentarios Adicionales</Label>
          <TextArea
            id="comments"
            name="comments"
            rows="6"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            required
          />
        </FormGroup>
        <SubmitButton type="submit">Enviar</SubmitButton>
      </ContactForm>
    </ContactSection>
  );
};

export default Contact;
