// Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterSection = styled.footer`
  padding: 2rem;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  margin: 0; /* Elimina el margen superior del footer para evitar espacio blanco */
`;

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que las columnas se envuelvan en pantallas pequeñas */
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
`;

const FooterColumn = styled.div`
  flex: 1;
  text-align: center;
  margin: 1rem 0; /* Espacio vertical entre las columnas en pantallas pequeñas */

  &:not(:last-child) {
    margin-right: 1rem; /* Espacio horizontal entre columnas en pantallas grandes */
  }

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    flex: 1 1 100%; /* Las columnas ocuparán el 100% del ancho en pantallas pequeñas */
    margin-right: 0; /* Elimina el margen derecho en pantallas pequeñas */
  }
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 0 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => (
  <FooterSection>
    <FooterContent>
      <FooterColumn>
    <FooterLink href="https://www.facebook.com/profile.php?id=61562957340967" target="_blank" rel="noopener noreferrer">Facebook</FooterLink> 
      {/*  <FooterLink href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</FooterLink> | */}
     { /* <FooterLink href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</FooterLink>*/}
        <p>&copy; {new Date().getFullYear()} ONVA SOFTWARE</p>
      </FooterColumn>
      <FooterColumn>
        <p></p> {/* Espacio para contenido adicional si es necesario */}
      </FooterColumn>
      <FooterColumn>
        <p>+52 8132396280</p>
        <p><a href="mailto:onvasoftware@gmail.com">onvasoftware@gmail.com</a></p>
      </FooterColumn>
    </FooterContent>
  </FooterSection>
);

export default Footer;
