import React, { useState } from 'react';
import styled from 'styled-components';
import { FaLaptopCode, FaSearch, FaPaintBrush } from 'react-icons/fa';
import Modal from 'react-modal';

// Estilos para la sección de servicios
const ServicesSection = styled.section`
  padding: 4rem 0;
  background-color: ${({ theme }) => theme.colors.background};
`;

const ServiceContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Service = styled.div`
  background: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 2rem;
  margin: 1rem;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 1rem;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 1rem;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.tertiary};
  margin-bottom: 1.5rem;
`;

const ServiceCTA = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const ModalContent = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  max-width: 500px;
  margin: auto;
  text-align: center;

  h2 {
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    color: black; // Color del texto de la ventana modal a negro
  }
`;

Modal.setAppElement('#root');

const Services = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (service) => {
    setSelectedService(service);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedService(null);
  };

  const services = [
    {
      title: 'Desarrollo web',
      description: 'Desarrollamos sitios web modernos y responsivos que se adaptan a cualquier dispositivo.',
      icon: <FaLaptopCode />,
      details: 'Ofrecemos desarrollo web completo, incluyendo frontend y backend, asegurando que tu sitio sea rápido, seguro y escalable.',
    },
    {
      title: 'SEO Optimización',
      description: 'Mejoramos el posicionamiento de tu sitio en los motores de búsqueda para aumentar tu visibilidad online.',
      icon: <FaSearch />,
      details: 'Implementamos estrategias de SEO para mejorar la visibilidad de tu sitio en los motores de búsqueda y atraer más tráfico orgánico.',
    },
    {
      title: 'Diseño UI/UX',
      description: 'Creemos diseños atractivos y fáciles de usar para mejorar la experiencia del usuario en tu sitio web.',
      icon: <FaPaintBrush />,
      details: 'Nuestros diseños se centran en la experiencia del usuario, creando interfaces intuitivas y visualmente atractivas.',
    },
  ];

  return (
    <ServicesSection>
      <ServiceContainer>
        {services.map((service, index) => (
          <Service key={index}>
            <IconWrapper>{service.icon}</IconWrapper>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
            <ServiceCTA onClick={() => openModal(service)}>Más Información</ServiceCTA>
          </Service>
        ))}
      </ServiceContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            border: 'none',
            borderRadius: '8px',
            padding: '0',
            inset: '50% auto auto 50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        {selectedService && (
          <ModalContent>
            <h2>{selectedService.title}</h2>
            <p>{selectedService.details}</p>
            <ServiceCTA onClick={closeModal}>Cerrar</ServiceCTA>
          </ModalContent>
        )}
      </Modal>
    </ServicesSection>
  );
};

export default Services;
