import React from 'react';
import Header from '../components/Navbar';
import About from '../components/About';

const AboutPage = () => (
  <>
    <Header />
    <About />

  </>
);

export default AboutPage;
