const theme = {
  colors: {
    primary: '#2D2D2D', // Gris oscuro para el fondo del navbar
    secondary: '#6FC4FF', // Color acento para hover y botones
    tertiary: '#00000',
    text: '#FFFFFF', // Blanco para el texto
    background: '#F5F5F5', // Fondo principal de la página
    cardBackground: '#404040', // Fondo de las tarjetas
    border: '#E0E0E0', // Color de borde
    link: '#3498db', // Color de los enlaces
    linkHover: '#1d8cf8', // Color de los enlaces al pasar el ratón
  },
  fonts: {
    primary: 'Arial, sans-serif',
    secondary: 'Georgia, serif',
  },
  breakpoints: {
    mobile: '768px',
    tablet: '1024px',
  },
};

export default theme;
