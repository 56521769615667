import React from 'react';
import styled from 'styled-components';

const PortfolioSection = styled.section`
  padding: 4rem 2rem;
  background-color: #f8f9fa; /* Color de fondo claro para mejor contraste */

  h2 {
    color: #333; /* Color del texto del encabezado <h2> */
    text-align: center; /* Centra el texto del encabezado */
    margin-bottom: 2rem; /* Espaciado inferior para separar el título del contenido */
  }
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;

  
`;

const PortfolioItem = styled.div`
  background: white;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera para destacar los elementos */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const PortfolioImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px; /* Bordes redondeados para la imagen */
`;

const PortfolioTitle = styled.h3`
  margin-top: 1rem;
  color: #333; /* Color de texto negro */
`;


const Portfolio = () => (
  <PortfolioSection>
    <h2>Algunos Clientes</h2>
    <PortfolioGrid>
      <PortfolioItem>
        <PortfolioImage src="https://raw.githubusercontent.com/rhompe/imagnes/main/Portafolio/rampas.png" alt="Project 1" />
        <PortfolioTitle>Equipo Automotriz Monterrey</PortfolioTitle>
      </PortfolioItem>
      <PortfolioItem>
        <PortfolioImage src="https://raw.githubusercontent.com/rhompe/imagnes/main/Portafolio/construccion%20.png" alt="Project 2" />
        <PortfolioTitle>Construcción</PortfolioTitle>
      </PortfolioItem>
      <PortfolioItem>
        <PortfolioImage src="https://raw.githubusercontent.com/rhompe/imagnes/main/Portafolio/barber.png" alt="Project 3" />
        <PortfolioTitle>True Barber</PortfolioTitle>
      </PortfolioItem>
    </PortfolioGrid>
  </PortfolioSection>
);

export default Portfolio;
