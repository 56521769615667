import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: url('https://raw.githubusercontent.com/rhompe/imagnes/main/Portafolio/slideinicial.jpg') no-repeat center center/cover;
  background-size: cover; /* Asegura que la imagen cubra todo el área */
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 50vh; /* Ajusta la altura en pantallas móviles */
  }
`;

const HeroContent = styled.div`
  text-align: center;
  color: #fff;
  background: rgba(45, 45, 45, 18); /* Fondo oscuro con mayor opacidad para mejor contraste */
  padding: 2rem;
  border-radius: 12px; /* Bordes más redondeados para un aspecto más moderno */
  max-width: 80%; /* Ajusta el ancho máximo para una mejor adaptación en pantallas pequeñas */
  margin: 0 auto; /* Centra el contenido horizontalmente */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra el contenido vertical y horizontalmente */

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
    max-width: 90%; /* Aumenta el ancho máximo en pantallas más pequeñas */
  }
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin: 0 0 0.5rem 0; /* Espacio ajustado debajo del título */
  font-weight: 700; /* Fuente más gruesa para mayor impacto */

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1.8rem; /* Reduce el tamaño del texto en pantallas más pequeñas */
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin: 0; /* Elimina márgenes por defecto */
  font-weight: 300; /* Fuente más ligera para el subtítulo */

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1rem; /* Reduce el tamaño del texto en pantallas más pequeñas */
  }
`;

const HeroSection = () => (
  <HeroContainer>
    <HeroContent>
      <HeroTitle>Tu Socio en Diseño Web</HeroTitle>
      <HeroSubtitle>
        Ofrecemos soluciones web a medida para impulsar tu negocio.
        <br />
        Contáctanos para comenzar tu proyecto
      </HeroSubtitle>
    </HeroContent>
  </HeroContainer>
);

export default HeroSection;
