import React from 'react';
import Header from '../components/Navbar';
import Quotation from '../components/Quotation';

const QuotationPage = () => (
  <>
    <Header />
    <Quotation />
  </>
);

export default QuotationPage;
