import React from 'react';
import styled from 'styled-components';
import Header from '../components/Navbar';
import Contact from '../components/Contact';

// Estilo para el título de la página
const Title = styled.h2`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.primary}; /* Cambia esto según el color que prefieras */
  text-align: center;
  margin: 2rem 0 -3rem 0 ;
  font-weight: bold;
  text-transform: uppercase; /* Convierte el texto a mayúsculas, si deseas */
`;

const ContactPage = () => (
  <>
    <Header />
    <Title>Contactar</Title>
    <Contact />
  </>
);

export default ContactPage;
