import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

// Estiliza el navbar
const CustomNavbar = styled(Navbar)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 1rem 2rem; // Ajusta el padding para más espacio
`;

// Estiliza los enlaces del navbar
const CustomNavLink = styled(Nav.Link)`
  color: ${({ theme }) => theme.colors.text} !important;
  font-size: 1rem;
  margin-right: 1.5rem; // Espacio entre los enlaces
  text-decoration: none !important;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary} !important;
    text-decoration: none !important;
  }
`;

// Estiliza el logo
const Logo = styled.img`
  height: 40px;
  margin-right: 10px;
`;

// Estiliza el Toggle del Navbar
const CustomToggle = styled(Navbar.Toggle)`
  border: none;
  &:focus, &:hover {
    background-color: transparent;
  }
`;

// Estiliza el ícono de la hamburguesa
const CustomFaBars = styled(FaBars)`
  color: white; /* Cambia esto al color que desees */
`;

// Estiliza el Brand del Navbar
const Brand = styled(Navbar.Brand)`
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
`;

// Cambiar el texto según el tamaño de la pantalla
const BrandText = styled.span`
  @media (max-width: 576px) {
    display: none;
  }
`;

const SmallScreenText = styled.span`
  display: none;
  @media (max-width: 576px) {
    display: inline;
  }
`;

const NavbarComponent = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <CustomNavbar expanded={expanded} expand="lg">
      <Container>
        <Brand as={Link} to="/">
          <Logo src="https://raw.githubusercontent.com/rhompe/imagnes/main/ONVA/onvasin.png" /> {/* Cambia "logo_url_here.png" por la URL de tu logo */}
          {/* <BrandText>ONVA</BrandText>
          <SmallScreenText>ONVA Software</SmallScreenText>*/}
        </Brand>
        <CustomToggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        >
          <CustomFaBars />
        </CustomToggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <CustomNavLink as={Link} to="/" onClick={() => setExpanded(false)}>Inicio</CustomNavLink>
            <CustomNavLink as={Link} to="/services" onClick={() => setExpanded(false)}>Servicios</CustomNavLink>
            <CustomNavLink as={Link} to="/portfolio" onClick={() => setExpanded(false)}>Clientes</CustomNavLink>
            <CustomNavLink as={Link} to="/about" onClick={() => setExpanded(false)}>Nosotros</CustomNavLink>
            <CustomNavLink as={Link} to="/cotizacion" onClick={() => setExpanded(false)}>Cotización</CustomNavLink>
            <CustomNavLink as={Link} to="/contact" onClick={() => setExpanded(false)}>Contacto</CustomNavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </CustomNavbar>
  );
};

export default NavbarComponent;
