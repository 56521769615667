import React from 'react';
import Header from '../components/Navbar';
import Portfolio from '../components/Portfolio';

const PortfolioPage = () => (
  <>
    <Header />
    <Portfolio />
  </>
);

export default PortfolioPage;
