import React, { useState } from 'react';
import styled from 'styled-components';
import { FaDesktop, FaDatabase, FaFileCode } from 'react-icons/fa';
import Modal from 'react-modal';

// Estilos para la sección de cotizaciones
const QuotationSection = styled.section`
  padding: 4rem 1rem; /* Ajuste para dispositivos móviles */
  background-color: ${({ theme }) => theme.colors.background};
`;

const QuotationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajuste automático para mostrar tarjetas en líneas responsivas */
  gap: 1rem; /* Espacio entre las tarjetas */
  justify-content: center; /* Centrar el contenido en el contenedor */
`;

const QuotationCard = styled.div`
  background: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 100%; /* Asegura que la tarjeta no sea más ancha que el contenedor */

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem; /* Ajuste del tamaño del icono */
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 1rem;
`;

const QuotationTitle = styled.h3`
  font-size: 1.25rem; /* Tamaño de fuente ajustado */
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 0.5rem; /* Espacio debajo del título */
`;

const QuotationDescription = styled.p`
  font-size: 0.875rem; /* Tamaño de fuente ajustado */
  color: ${({ theme }) => theme.colors.tertiary};
  margin-bottom: 1rem; /* Espacio debajo de la descripción */
`;

const QuotationCTA = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const ModalContent = styled.div`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.background}; /* Asegúrate de que coincida con el fondo deseado */
  border-radius: 8px;
  max-width: 500px; /* Ajusta el ancho máximo del modal */
  max-height: 80vh; /* Ajusta la altura máxima del modal */
  overflow-y: auto; /* Agrega barra de desplazamiento vertical si el contenido es demasiado largo */
  margin: auto;
  text-align: center;

  h2 {
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    color: black; /* Texto en negro */
    margin-bottom: 2rem; /* Espacio adicional debajo del texto */
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem; /* Espacio entre los botones */
`;

Modal.setAppElement('#root');

const Quotation = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (service) => {
    setSelectedService(service);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedService(null);
  };

  const services = [
    {
      title: 'Páginas Web Simples',
      description: 'Desarrollamos páginas web sencillas y efectivas para tu negocio.',
      icon: <FaDesktop />,
      details: 'Creamos sitios web simples pero funcionales que cumplen con tus necesidades básicas y son fáciles de mantener.',
    },
    {
      title: 'Páginas Web con Base de Datos',
      description: 'Creación de sitios con base de datos para una gestión avanzada.',
      icon: <FaDatabase />,
      details: 'Desarrollamos sitios web que se conectan a bases de datos para manejar información de manera eficiente y segura.',
    },
    {
      title: 'Sistemas Personalizados',
      description: 'Desarrollo de sistemas a medida para satisfacer tus necesidades específicas.',
      icon: <FaFileCode />,
      details: 'Creamos sistemas personalizados según tus requisitos específicos, desde aplicaciones empresariales hasta soluciones de gestión.',
    },
    {
      title: 'Páginas Web Estáticas',
      description: 'Diseñamos páginas web estáticas con un diseño atractivo y funcional.',
      icon: <FaDesktop />,
      details: 'Desarrollamos páginas web estáticas con un enfoque en el diseño visual y la usabilidad, ideales para mostrar información estática.',
    },
    {
      title: 'Páginas Web Medianas',
      description: 'Soluciones intermedias para un mayor alcance y funcionalidad.',
      icon: <FaDesktop />,
      details: 'Creamos sitios web con características intermedias que ofrecen un equilibrio entre funcionalidad y diseño.',
    },
    {
      title: 'Páginas Web Grandes',
      description: 'Desarrollo de páginas web complejas con múltiples funcionalidades.',
      icon: <FaDesktop />,
      details: 'Desarrollamos sitios web grandes y complejos con varias funcionalidades avanzadas y una arquitectura robusta.',
    },
  ];

  return (
    <QuotationSection>
      <QuotationContainer>
        {services.map((service, index) => (
          <QuotationCard key={index}>
            <IconWrapper>{service.icon}</IconWrapper>
            <QuotationTitle>{service.title}</QuotationTitle>
            <QuotationDescription>{service.description}</QuotationDescription>
            <QuotationCTA onClick={() => openModal(service)}>Más Información</QuotationCTA>
          </QuotationCard>
        ))}
      </QuotationContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)', /* Color de overlay */
          },
          content: {
            border: 'none',
            borderRadius: '8px',
            padding: '0',
            maxWidth: '90%',
            width: 'auto', /* Ajuste automático del ancho */
            maxHeight: '90vh', /* Ajusta la altura máxima del modal */
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'transparent', /* Fondo transparente */
            overflow: 'hidden', /* Evita el desbordamiento del contenido */
          },
        }}
      >
        {selectedService && (
          <ModalContent>
            <h2>{selectedService.title}</h2>
            <p>{selectedService.details}</p>
            <ButtonGroup>
              <QuotationCTA onClick={closeModal}>Cerrar</QuotationCTA>
              <QuotationCTA 
                onClick={() => {
                  window.open(`https://wa.me/8132396280?text=${encodeURIComponent(`Quiero cotizar el servicio: ${selectedService.title}. Detalles: ${selectedService.details}`)}`, '_blank');
                }}
              >
                Cotizar
              </QuotationCTA>
            </ButtonGroup>
          </ModalContent>
        )}
      </Modal>
    </QuotationSection>
  );
};

export default Quotation;
