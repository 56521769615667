import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Home from './pages/Home';
import ServicesPage from './pages/Services';
import PortfolioPage from './pages/Portfolio';
import AboutPage from './pages/About';
import QuotationPage from './pages/Quotation';
import ContactPage from './pages/Contact';
import Footer from './components/Footer'; // Asegúrate de que la ruta sea correcta
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';
import styled from 'styled-components';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Hace que el contenedor ocupe al menos toda la altura de la ventana */
  margin: 0; /* Elimina márgenes del contenedor principal */
  padding: 0; /* Elimina rellenos del contenedor principal */
`;

const MainContent = styled.main`
  flex: 1; /* Hace que el contenido principal ocupe todo el espacio disponible */
  padding: 0; /* Asegúrate de que el contenido principal no tenga relleno adicional */
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContainer>
          <GlobalStyle />
          <MainContent>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/portfolio" element={<PortfolioPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/cotizacion" element={<QuotationPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </MainContent>
          <Footer />
        </AppContainer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
